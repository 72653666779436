import React from "react"
import Seo from "../components/SEO"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Features from "../components/Features/Features"
import RichText from "../components/RichText"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import {
  RolesWrapperStyles,
  SkillsWrapperStyles,
} from "../components/BasicTextModule/BasicTextModuleStyles"
import Skill from "../components/Skills/Skill"
import Role from "../components/Skills/Role"
import CarouselGallery from "../components/Carousel/CarouselGallery"

const ProductTemplateStyles = styled.div`
  .container {
    display: flex;
    flex-wrap: wrap;
    .column {
      flex: 0 0 100%;

      @media (min-width: 768px) {
        flex-basis: 50%;

        &:nth-child(1) {
          padding-right: 20px;
        }

        &:nth-child(2) {
          padding-left: 20px;
        }

        > * {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      > * {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
`

const ProductGallery = styled.section`
  width: 100%;
  &.gradient {
    background-color: hsla(46, 100%, 50%, 1);
    background-image: radial-gradient(
        at 21% 1%,
        hsla(21, 78%, 66%, 1) 0px,
        transparent 50%
      ),
      radial-gradient(at 23% 45%, hsla(343, 60%, 79%, 1) 0px, transparent 50%),
      radial-gradient(at 28% 96%, hsla(323, 65%, 72%, 1) 0px, transparent 50%),
      radial-gradient(at 72% 60%, hsla(43, 96%, 70%, 1) 0px, transparent 50%),
      radial-gradient(at 60% 60%, hsla(171, 73%, 76%, 1) 0px, transparent 50%),
      radial-gradient(at 96% 83%, hsla(300, 81%, 65%, 1) 0px, transparent 50%),
      radial-gradient(at 98% 27%, hsla(208, 92%, 76%, 1) 0px, transparent 50%);
  }
  overflow: hidden;

  > .container {
    display: flex;
    align-items: center;
    padding: 36px;
    position: relative;
    @media (max-width: 480px) {
      flex-direction: column;
      padding: 36px 0;
    }
  }

  .controls {
    width: 100%;
    height: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    button {
      height: 100%;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 32px;
      background: transparent;
      border: none;
      color: #fff;
      font-size: 36px;
      cursor: pointer;
      pointer-events: all;
    }

    @media (max-width: 768px) {
      width: 100lvw;
      button {
        padding: 0;
      }
    }
  }
  .carousel-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    cursor: grab;
    overflow: hidden;
    @media (max-width: 768px) {
      width: auto;
    }
  }
  .gallery-thumb {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    cursor: grab;
    margin: 0;
    &.solo {
      width: calc(50% - var(--gap) / 2);
      margin: 16px;
    }
    @media (max-width: 768px) {
      transform: scale(0.5);
      &.solo {
        width: 100%;
        transform: none;
        margin: 0 0 32px;
        .gatsby-image-wrapper {
          width: 100% !important;
          height: 240px !important;
        }
      }
    }
  }
`

const ProductTemplate = (contentfulProduct) => {
  const {
    headerImage,
    title,
    introduction,
    gallery,
    description,
    url,
    skills,
    roles,
  } = contentfulProduct
  const productHeaderImage = getImage(headerImage)
  return (
    <>
      <Seo title={title} />
      <SimpleBanner title={title} introduction={introduction} url={url}>
        <GatsbyImage
          className="banner__image"
          image={productHeaderImage}
          alt={title}
          objectFit="cover"
        />
      </SimpleBanner>
      <ProductTemplateStyles className="section">
        {roles && (
          <div className="container container__tight">
            <RolesWrapperStyles>
              <div className="roles">
                <div className="roles__content">
                  {roles.map((role) => (
                    <Role key={role.id} {...role} />
                  ))}
                </div>
              </div>
            </RolesWrapperStyles>
          </div>
        )}
        {skills && (
          <div className="container container__tight">
            <SkillsWrapperStyles>
              <div className="skills">
                <div className="skills__content">
                  <p>Tech Stack</p>
                  <div className="skills__showcase">
                    {skills.map((skill) => (
                      <Skill key={skill.id} {...skill} />
                    ))}
                  </div>
                </div>
              </div>
            </SkillsWrapperStyles>
          </div>
        )}
        <div className="container container__tight">
          {description && (
            <div className="column">
              <RichText richText={description} />
            </div>
          )}
        </div>
      </ProductTemplateStyles>
      {gallery && (
        <ProductGallery
          className={`section ${gallery.length > 2 && "gradient"}`}
        >
          <div className="container container__tight">
            {gallery.length > 2 ? (
              <CarouselGallery gallery={gallery} />
            ) : (
              gallery.map((item, index) => {
                let galleyImage = getImage(item)
                return (
                  <div className="gallery-thumb solo" key={index}>
                    <GatsbyImage image={galleyImage} alt={"Gallery Image"} />
                  </div>
                )
              })
            )}
          </div>
        </ProductGallery>
      )}
      <Features
        title="Featured Projects"
        introduction="Browse selected works by Felix Dasgupta."
      />
    </>
  )
}

export default ProductTemplate
