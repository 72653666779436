import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { useSpringCarousel } from "react-spring-carousel"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"

const MyImage = ({ image, index }) => {
  const item = getImage(image)

  return <GatsbyImage image={item} alt={`gallery-${index}`} />
}

const CarouselGallery = ({ gallery }) => {
  const { carouselFragment, slideToPrevItem, slideToNextItem } =
    useSpringCarousel({
      withLoop: true,
      items: gallery.map((item, i) => ({
        id: i.id,
        renderItem: (
          <div className="gallery-thumb">
            <MyImage image={item} index={i} />
          </div>
        ),
      })),
    })

  return (
    <>
      <div className="controls">
        <button onClick={slideToPrevItem}>
          <MdChevronLeft />
        </button>
        <button onClick={slideToNextItem}>
          <MdChevronRight />
        </button>
      </div>
      <div className="carousel-wrapper">{carouselFragment}</div>
    </>
  )
}

export default CarouselGallery
