import React from "react"
import { graphql } from "gatsby"
import ProductTemplate from "../../templates/product-template"
import Seo from "../../components/SEO"
import Layout from "../../components/Layout"

const Product = ({ data: { contentfulProduct } }) => {
  return (
    <>
      <Seo title={contentfulProduct.title} />
      <Layout>
        <ProductTemplate {...contentfulProduct} />
      </Layout>
    </>
  )
}

export const data = graphql`
  query productQuery($id: String) {
    contentfulProduct(id: { eq: $id }) {
      title
      introduction
      url
      headerImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      skills {
        id
        name
        image {
          file {
            url
          }
        }
      }
      roles {
        id
        name
        icon {
          gatsbyImageData(
            width: 50
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      description {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
            __typename
          }
        }
      }
      gallery {
        gatsbyImageData(
          layout: FIXED
          height: 600
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default Product
